import { TextField } from '@mui/material';
import React, { ChangeEvent } from 'react';

const DeviceFilter = ({ onFilterChange }: { onFilterChange: (filter: string) => void }) => {
    const handleChange = (e : ChangeEvent<HTMLInputElement>) => {
        onFilterChange(e.target.value);
    };

    return (
        <div className="device-filter">
            <TextField id="outlined-basic" label="Filter devices..." variant="outlined" onChange={handleChange} size="small"/>
        </div>
    );
};

export default DeviceFilter;
