import React from 'react';
import { Button, Drawer, List, ListItem, ListItemText } from '@mui/material';
import BenchmarkDevice from '../../types/IBenchmarkDevice'

const CartDrawer = ({ open, onClose, cartItems, onViewCartClick } : {open : boolean; onClose: () => void; cartItems : BenchmarkDevice[]; onViewCartClick: () => void }) => {
    return (
        <Drawer anchor="right" open={open} onClose={onClose} >
            <List style={{ width: '250px' }} sx={{ zIndex: (theme) => theme.zIndex.appBar - 1 }}>
                <ListItem key={0}>
                    <ListItemText primary={'-----'} secondary={'-----'}/>
                </ListItem>
                {cartItems.map((item) => (
                    <ListItem key={item.ip}>
                        <ListItemText primary={`${item.name}`} secondary={'-----'} />
                    </ListItem>
                ))}
                <ListItem>
                    <Button variant="contained" color="primary" onClick={onViewCartClick}>
                        View Full Cart
                    </Button>
                </ListItem>
            </List>
        </Drawer>
    );
};

export default CartDrawer;
