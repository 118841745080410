export default interface IContainerImage {
    id: number;
    repository: string;
    tag: string;
    platform: string;
    exposed_ports: Array<string>;
    compressed_image_size: number;
}

export const INFERENCE_SERVER_TYPES = [
    {
        value: 'ros2',
        label: 'ROS 2',
    },
    {
        value: 'triton',
        label: 'Triton',
    },
];
