import React from 'react';
import DeviceCard from './DeviceCard';
import BenchmarkDevice from '../../types/IBenchmarkDevice'

const DeviceList = ({ devices, onDeviceClick, addToCart, cartItems} : { devices: Array<BenchmarkDevice>; onDeviceClick: (device : BenchmarkDevice) => void; addToCart: (device : BenchmarkDevice) => void, cartItems : BenchmarkDevice[]}) => {
    return(
        <div className="device-list" style={{ marginTop: '0.8rem' }}>
            {devices.map((device) => (
                <DeviceCard key={device.ip} device={device} onClick={onDeviceClick} addToCart={addToCart} buttonOption={cartItems.findIndex((item) => item.ip === device.ip) >= 0 ? true : false}/>
            ))}
        </div>
    )
};

export default DeviceList;
